<script>
  import { onMount, getContext } from 'svelte';
  import { store, results, getStore } from '../../../stores/store.js';

  // Components
  import Input from '../../fields/Input.svelte';
  import Textarea from '../../fields/Textarea.svelte';
  import Logs from '../../Logs.svelte';

  import { ConsentType } from '../../../utils/consent-type.js';
  import PopupGeneratePayload from '../modal/PopupGeneratePayload.svelte';

  let typeKey = 'payment';
  const consentType = getStore('business').consentType;

  onMount(() => {
    if (consentType === ConsentType.PAYMENT) {
      typeKey = 'payment';
    } else if (consentType === ConsentType.DATA_SHARING) {
      typeKey = 'dataSharing';
    }
  });

  const { open } = getContext('simple-modal');

  const showPayloadGeneratorPopup = () => {
    open(PopupGeneratePayload);
  };
</script>

<Input
  text="Consentimento API"
  key="consentsAPIField"
  bind:value={$store.business[typeKey].consentsAPI}
/>
<div class="payload-container">
  {#if consentType === ConsentType.DATA_SHARING}
    <button
      on:click|preventDefault={showPayloadGeneratorPopup}
      class="btn btn-generate-payload"
    >
      <span class="material-icons icon">edit</span>
    </button>
  {/if}
  <Textarea
    text="Consentimento payload (JSON)"
    key="consentPayload"
    bind:value={$store.business[typeKey].consentPayload}
  />
</div>
<slot name="buttons" />
{#if $results[typeKey]?.consentIntent}
  <div class="result">
    <h3>Resultado da intenção de consentimento:</h3>
    <Logs logs={$results[typeKey]?.consentIntent} />
  </div>
{/if}

<style>
  .payload-container {
    position: relative;
  }
  .btn {
    background: var(--primary-color);
    color: var(--white-color);
    padding: 1rem;
    border-radius: 0.5rem;
    width: min-content;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-generate-payload {
    position: absolute;
    top: 70px;
    right: 20px;
  }
</style>
