<script>
  import { store, results } from '../../../stores/store.js';

  // Components
  import Input from '../../fields/Input.svelte';
  import Logs from '../../Logs.svelte';
</script>

<Input
  text="Rejeitar o consentimento API"
  bind:value={$store.business.dataSharing.rejectAPI}
  key="rejectAPIField"
/>
<slot name="buttons" />
{#if $results?.dataSharing?.rejectDataSharing}
  <div class="result">
    <h3>Resultado da revogação do consentimento:</h3>
    <Logs logs={$results?.dataSharing?.rejectDataSharing} />
  </div>
{/if}
