<script>
  import { store, results, getStore, getResult } from '../../../stores/store.js';
  import { notifications } from '../../../stores/notifications.js';
  import Api from '../../../services/Api.js';

  // Components
  import Input from '../../fields/Input.svelte';
  import Textarea from '../../fields/Textarea.svelte';
  import Logs from '../../Logs.svelte';
  import Notice from '../../Notice.svelte';
  import { getConfigData } from '../../../utils/handleConfigData.js';

  const generateEndToEndId = () => {
    const paymentPayload = JSON.parse(
      getStore('business').payment?.paymentPayload
    );

    var paymentData;
    if (paymentPayload?.data instanceof Array) {
      paymentData = generateEndToEndForMultiplePayments(paymentPayload?.data);
    } else {
      paymentData = generateEndToEndForPayment(paymentPayload?.data, 0);
    }

    if (paymentData) {
      const newPayload = {
        ...paymentPayload,
        data: paymentData
      };

      updatePayload(newPayload);
    }
  };

  const generateEndToEndForMultiplePayments = (payments) => {
    const paymentArray = [];

    for (var index in payments) {
      var paymentWithEndToEnd = generateEndToEndForPayment(payments[index], parseInt(index));
      if (!paymentWithEndToEnd) {
        return undefined;
      }

      paymentArray.push(paymentWithEndToEnd);
    }

    return paymentArray;
  }

  const generateEndToEndForPayment = (payment, index) => {
    const consent = JSON.parse(
      getStore('business').payment?.consentPayload
    );
    const paymentType = getPaymentType(consent);

    const date = getEndToEndDate(consent, paymentType, index);
    const cnpj = payment?.cnpjInitiator;
    const hash = generateHash(11);
    if (validateInfo(payment, cnpj, date, hash)) {
      const newPayload = {
        ...payment,
        endToEndId: mountEndToEndId(cnpj, date, hash),
      };

      return newPayload;
    }

    return undefined;
  }

  const updatePayload = (newPayload) => {
    store.update((store) => ({
      ...store,
      business: {
        ...store.business,
        payment: {
          ...store.business.payment,
          paymentPayload: JSON.stringify(newPayload, null, 4),
        },
      },
    }));

    notifications.success('EndToEndId adicionado com sucesso!', 2000);
  }

  const mountEndToEndId = (cnpj, date, hash) => {
    return 'E' + cnpj.substring(0, 8) + date + hash;
  };

  const validateInfo = (paymentPayload, cnpj, date, hash) => {
    if (!paymentPayload) {
      notifications.danger(`É necessário ter um JSON preenchido`, 2000);
      return false;
    }

    if (!cnpj) {
      notifications.danger(
        `Não foi encontrado o campo cnpjInitiator no JSON`,
        2000
      );
      return false;
    }

    if (!date) {
      notifications.danger(`Erro ao criar uma nova data`, 2000);
      return false;
    }

    if (!hash) {
      notifications.danger(`É ao gerar um hash para o campo EndToEndId`, 2000);
      return false;
    }

    return true;
  };

  const getEndToEndDate = (consent, paymentType, index) => {
    var date = getNextPaymentDate(consent, paymentType, index);

    var endToEndDate =
      date.getUTCFullYear() +
      String(date.getUTCMonth() + 1).padStart(2, '0') +
      String(date.getUTCDate()).padStart(2, '0');

    if (consent?.data?.payment?.schedule) {
      endToEndDate += '1500';
    } else {
      var now = new Date();
      endToEndDate +=
        String(now.getUTCHours()).padStart(2, '0') +
        String(now.getUTCMinutes()).padStart(2, '0');
    }

    return endToEndDate;
  };

  const getNextPaymentDate = (consent, paymentType, index) => {
    var startDate = consent?.data?.payment?.date ||
      consent?.data?.payment?.schedule?.single?.date ||
      consent?.data?.payment?.schedule?.daily?.startDate ||
      consent?.data?.payment?.schedule?.weekly?.startDate ||
      consent?.data?.payment?.schedule?.monthly?.startDate ||
      consent?.data?.payment?.schedule?.custom?.dates[index] ||
      new Date().toISOString();

    var date = getDateFromStringOrCurrent(startDate);

    switch (paymentType) {
      case 'daily':
        date.setDate(date.getDate() + index);
        break;
      case 'weekly':
        const currentDayOfWeek = date.getDay();
        const dayOfWeek = getDayOfTheWeek(consent?.data?.payment?.schedule?.weekly?.dayOfWeek) || currentDayOfWeek;
        const daysUntilNextDay = (dayOfWeek - currentDayOfWeek + 7) % 7;
        date.setDate(date.getDate() + daysUntilNextDay + index * 7);
        break;
      case 'monthly':
        var dayOfMonth = consent?.data?.payment?.schedule?.monthly?.dayOfMonth || date.getDay();
        if (date.getDate() > dayOfMonth) {
            date.setDate(dayOfMonth);
            date.setMonth(date.getMonth() + index + 1);
        } else {
          date.setDate(dayOfMonth);
          date.setMonth(date.getMonth() + index);
        }

        if (date.getDate() !== dayOfMonth) {
          date.setDate(1);
        }
        break;
    }

    return date;
  }

  const getDayOfTheWeek = (day) => {
    switch (day) {
        case 'DOMINGO':
            return 0;
        case 'SEGUNDA_FEIRA':
            return 1;
        case 'TERCA_FEIRA':
            return 2;
        case 'QUARTA_FEIRA':
            return 3;
        case 'QUINTA_FEIRA':
            return 4;
        case 'SEXTA_FEIRA':
            return 5;
        case 'SABADO':
            return 6;
    }
  }

  // Utiliza esse construtor Date(year,month,day) para evitar erros de timezone
  const getDateFromStringOrCurrent = (dateString) => {
    var dateParts = dateString?.split('-');
    var finalDate;
    if (dateParts && dateParts.length === 3) {
      finalDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    }

    if (isNaN(finalDate)) {
      return new Date();
    }

    return finalDate;
  }

  const getPaymentType = (consent) => {
    if (consent?.data?.payment?.schedule) {
      return Object.keys(consent?.data?.payment?.schedule)[0];
    }

    return 'immediate';
  }

  const generateHash = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const refreshToken = async () => {
    const { result } = getResult('payment').authentication;
    const payload = {
      ...getConfigData(),
      refreshToken: result?.tokens?.refresh_token,
    };

    try {
      const apiResponse = await Api['post'](
        `/consent/refresh-token`,
        payload
      ).catch(({ response }) => response.data);

      if (!!apiResponse) {
        results.update((results) => {
          const updatedResult = {
            dataSharing: results.dataSharing,
            payment: {
              ...results.payment,
              authentication: {
                ...results.payment.authentication,
                result: {
                  ...results.payment.authentication.result,
                  tokens: apiResponse.tokens,
                },
              },
            },
          };
          
          return updatedResult;
        });
      }
    } catch (error) {
      notifications.danger(error, 3000);
    }
  };
</script>

{#if $results?.payment?.authentication?.result?.tokens}
  <Notice text={`Você está autenticado!`} dataCy="payment-message" />
  <div class="tokens-container">
    <Notice
      type="success"
      text={`Access Token:\n${$results?.payment?.authentication?.result?.tokens?.access_token}`}
    />
    <Notice
      type="success"
      text={`Refresh Token:\n${$results?.payment?.authentication?.result?.tokens?.refresh_token}`}
    />
  </div>
  <button on:click|preventDefault={refreshToken} class="btn-refresh"
    >Atualizar token</button
  >
{:else}
  <Notice
    type="warning"
    text="Para realizar o pagamento você precisa se autenticar antes."
    dataCy="payment-message"
  />
{/if}

<Input
  text="Pagamento API"
  key="paymentsAPIField"
  bind:value={$store.business.payment.paymentsAPI}
/>
<div class="payload-container">
  <div class="end2end-btn">
    <button class="btn" on:click|preventDefault={generateEndToEndId}
      >Gerar EndToEndId</button
    >
  </div>
  <Textarea
    text="Pagamento (JSON)"
    key="paymentPayload"
    bind:value={$store.business.payment.paymentPayload}
  />
</div>
<slot name="buttons" />
{#if $results?.payment?.makePayment}
  <div class="result">
    <h3>Resultado do pagamento:</h3>
    <Logs logs={$results?.payment?.makePayment} />
  </div>
{/if}

<style>
  .payload-container {
    position: relative;
  }
  .btn {
    background: var(--primary-color);
    color: var(--white-color);
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    width: min-content;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .end2end-btn {
    position: absolute;
    top: 5rem;
    right: 1rem;
  }

  .tokens-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.8rem;
  }

  .btn-refresh {
    color: var(--primary-color);
    padding: 1rem 3rem;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
</style>
