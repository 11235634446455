<script>
  import { onMount } from 'svelte';
  import { results, getStore } from '../../../stores/store.js';

  import Logs from '../../Logs.svelte';
  import Notice from '../../Notice.svelte';
  import Copy from '../../fields/Copy.svelte';
  import { ConsentType } from '../../../utils/consent-type.js';

  let typeKey = 'payment';

  onMount(() => {
    const consentType = getStore('business').consentType;
    if (consentType === ConsentType.PAYMENT) {
      typeKey = 'payment';
    } else if (consentType === ConsentType.DATA_SHARING) {
      typeKey = 'dataSharing';
    }
  });
</script>

{#if $results[typeKey]?.consentIntent?.data?.consentId || $results[typeKey]?.consentIntent?.data?.recurringConsentId}
  <Notice
    text="Você realizou a intenção de consentimento, clique no botão abaixo para obter a url de autenticação."
    dataCy="auth-message"
  />
{:else}
  <Notice
    type="warning"
    text="Para requisitar a url de autenticação você precisa realizar a intenção de consentimento antes."
    dataCy="auth-message"
  />
{/if}

<slot name="buttons" />

{#if $results[typeKey]?.authentication?.result}
  <div class="result">
    <h3>Resultado da autenticação:</h3>
    <Logs logs={$results[typeKey]?.authentication?.result} />
  </div>
{:else if $results[typeKey]?.authentication?.authUrl}
  <Copy
    value={$results[typeKey]?.authentication?.authUrl}
    title="Url de autenticação:"
  />
  <a
    href={$results[typeKey]?.authentication?.authUrl}
    class="link"
    target="_blank"
  >
    Click aqui para se autenticar
  </a>
{/if}

<style>
  .link {
    text-align: center;
    display: block;
  }
</style>
