<script>
  import { store } from '../stores/store.js';
  export let consentTypes = [];

  const handleClick = (tabKey) => () => ($store.business.consentType = tabKey);
</script>

<ul class="tabs">
  {#each consentTypes as item}
    <li class={$store.business.consentType === item.key ? 'active' : ''}>
      <span on:click={handleClick(item.key)}>{item.name}</span>
    </li>
  {/each}
</ul>
{#each consentTypes as item}
  {#if $store.business.consentType == item.key}
    <div class="box">
      <svelte:component this={item.component} />
    </div>
  {/if}
{/each}

<style>
  .box {
    margin-bottom: 10px;
    padding: 40px;
    border: thin solid var(--border-field-color);
    border-radius: 0 0 0.5rem 0.5rem;
    border-top: 0;
  }
  .tabs {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #dee2e6;
  }
  .tabs > li {
    margin-bottom: -1px;
    min-width: 12vw;
    text-align: center;
  }

  span {
    border: 1px solid transparent;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    display: block;
    padding: 1rem 1rem;
    cursor: pointer;
  }

  span:hover {
    border: thin solid var(--border-field-color);
  }

  li.active > span {
    background: var(--primary-color);
    color: var(--white-color);
    border-color: #dee2e6 #dee2e6 #fff;
  }

  @media only screen and (max-width: 768px) {
    .tabs {
      flex-wrap: nowrap;
    }
    .tabs > li {
      width: 100%;
    }

    span {
      border: thin solid var(--border-field-color);
    }
  }
</style>
