import { persistStore } from './persistStore';
import { get, writable } from 'svelte/store';
import { ConsentType } from '../utils/consent-type';

export const defaultData = {
  config: {
    global: {
      checkSSL: false,
    },
    security: {
      clientSignatureKey: '',
      clientTransportKey: '',
      clientTransportCert: '',
      caRootCertificate: '',
      idTokenEncKey: '',
      idTokenEncKid: '',
      clientSignatureKid: '',
    },
    as: {
      authorizationServerURL: '',
      redirectURI: '',
    },
    organization: {
      id: '',
      cnpj: '',
    },
    client: {
      id: '',
    },
  },
  business: {
    consentType: ConsentType.DATA_SHARING,
    payment: {
      consentsAPI: '',
      consentPayload: null,
      revokeAPI: '',
      revokePayload: null,
      paymentsAPI: '',
      paymentPayload: null,
      request: {
        url: '',
      },
    },
    dataSharing: {
      consentsAPI: '',
      consentPayload: null,
      rejectAPI: '',
      rejectPayload: null,
      financialDataAPI: '',
      request: {
        url: '',
      },
    },
  },
};

const defaultSections = [
  {
    id: 'conf',
    title: 'Configuração *',
    visible: false,
    component: 'ConfigForm',
  },
  {
    id: 'consent',
    title: 'Consentimento',
    visible: true,
    component: 'PhaseSelectorForm',
  },
];

export const defaultResultsPayment = {
  consentIntent: null,
  authentication: null,
  makePayment: null,
  revokePayment: null,
  getPayment: null,
};

export const defaultResultsDataSharing = {
  consentIntent: null,
  authentication: null,
  financialData: null,
  rejectDataSharing: null,
  getDataSharing: null,
};

export const defaultResults = {
  payment: defaultResultsPayment,
  dataSharing: defaultResultsDataSharing,
};

export const store = persistStore('data', defaultData);
export const sections = writable(defaultSections);
export const results = persistStore('results', defaultResults);

export const getStore = (key) => get(store)[key];
export const getResult = (key) => get(results)[key];
export const getResultPayment = (key) =>
  get(results)?.payment && get(results)?.payment[key];
export const getResultDataSharing = (key) => get(results)?.dataSharing[key];

export const getActiveStepIndex = (type) => {
  if (!get(results).payment && !get(results).dataSharing) {
    results.update(() => defaultResults);
  }

  const allResults =
    type === ConsentType.PAYMENT
      ? get(results).payment
      : get(results).dataSharing;

  const index = Object.keys(allResults)
    .filter((key) => key !== 'getPayment' || key !== 'getDataSharing')
    .map((key) => !!allResults[key])
    .lastIndexOf(true);
  return index === -1 ? 0 : index;
};

export const updateDataSharingResult = (key, data) => {
  results.update((results) => {
    const updatedResult = {
      payment: results.payment,
      dataSharing: mountDataSharingObject(key, data, results.dataSharing),
    };

    return updatedResult;
  });
};

const mountDataSharingObject = (key, data, dataSharing) => {
  return {
    ...dataSharing,
    [key]: data,
  };
};

export const updatePaymentResult = (key, data) => {
  results.update((results) => {
    const updatedResult = {
      dataSharing: results.dataSharing,
      payment: mountPaymentObject(key, data, results.payment),
    };

    return updatedResult;
  });
};

const mountPaymentObject = (key, data, payment) => {
  return {
    ...payment,
    [key]: data,
  };
};
