export const dataSharingPermissions = {
  ACCOUNTS: [
    'ACCOUNTS_READ',
    'ACCOUNTS_BALANCES_READ',
    'ACCOUNTS_TRANSACTIONS_READ',
    'ACCOUNTS_OVERDRAFT_LIMITS_READ',
  ],
  CREDIT_CARDS: [
    'CREDIT_CARDS_ACCOUNTS_READ',
    'CREDIT_CARDS_ACCOUNTS_BILLS_READ',
    'CREDIT_CARDS_ACCOUNTS_BILLS_TRANSACTIONS_READ',
    'CREDIT_CARDS_ACCOUNTS_LIMITS_READ',
    'CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ',
  ],
  CUSTOMERS_PERSONAL: [
    'CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ',
    'CUSTOMERS_PERSONAL_ADITTIONALINFO_READ',
  ],
  CUSTOMERS_BUSINESS: [
    'CUSTOMERS_BUSINESS_IDENTIFICATIONS_READ',
    'CUSTOMERS_BUSINESS_ADITTIONALINFO_READ',
  ],
  FINANCINGS: [
    'FINANCINGS_READ',
    'FINANCINGS_SCHEDULED_INSTALMENTS_READ',
    'FINANCINGS_PAYMENTS_READ',
    'FINANCINGS_WARRANTIES_READ',
  ],
  INVOICE_FINANCINGS: [
    'INVOICE_FINANCINGS_READ',
    'INVOICE_FINANCINGS_SCHEDULED_INSTALMENTS_READ',
    'INVOICE_FINANCINGS_PAYMENTS_READ',
    'INVOICE_FINANCINGS_WARRANTIES_READ',
  ],
  LOANS: [
    'LOANS_READ',
    'LOANS_SCHEDULED_INSTALMENTS_READ',
    'LOANS_PAYMENTS_READ',
    'LOANS_WARRANTIES_READ',
  ],
  UNARRANGED_ACCOUNTS_OVERDRAFT: [
    'UNARRANGED_ACCOUNTS_OVERDRAFT_READ',
    'UNARRANGED_ACCOUNTS_OVERDRAFT_SCHEDULED_INSTALMENTS_READ',
    'UNARRANGED_ACCOUNTS_OVERDRAFT_PAYMENTS_READ',
    'UNARRANGED_ACCOUNTS_OVERDRAFT_WARRANTIES_READ',
  ],
  RESOURCES: ['RESOURCES_READ'],
  BANK_FIXED_INCOMES: ['BANK_FIXED_INCOMES_READ'],
  CREDIT_FIXED_INCOMES: ['CREDIT_FIXED_INCOMES_READ'],
  FUNDS: ['FUNDS_READ'],
  VARIABLE_INCOMES: ['VARIABLE_INCOMES_READ'],
  TREASURE_TITLES: ['TREASURE_TITLES_READ'],
  EXCHANGES: ['EXCHANGES_READ'],
};