<script>
  import Tabs from '../Tabs.svelte';
  import MakePaymentForm from './MakePaymentForm.svelte';
  import MakeDataSharingForm from './MakeDataSharingForm.svelte';
  import { ConsentType } from '../../utils/consent-type';

  let consentTypes = [
    {
      id: 1,
      key: ConsentType.DATA_SHARING,
      name: 'Dados Cadastrais e Transacionais',
      component: MakeDataSharingForm,
    },
    {
      id: 2,
      key: ConsentType.PAYMENT,
      name: 'Iniciação de Pagamento',
      component: MakePaymentForm,
    },
  ];
</script>

<div class="phase-selector"><Tabs {consentTypes} /></div>

<style>
</style>
