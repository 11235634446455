<script>
  export let value,
    text,
    type = 'text',
    key = '',
    accept = '.json';
  function typeAction(node) {
    node.type = type;
  }
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->
<label class={$$props.class}>
  {text}
  {#if type === 'file'}
    <input
      use:typeAction
      class="input-field"
      name={key}
      id={key}
      bind:value
      on:change
      data-cy={$$props.dataCy}
      {accept}
    />
  {:else}
    <input
      use:typeAction
      class="input-field"
      name={key}
      id={key}
      bind:value
      on:change
      data-cy={$$props.dataCy}
    />
  {/if}
</label>

<style>
  .input-field {
    border: thin solid var(--border-field-color);
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
    border-radius: 0.5rem;
    width: 100%;
    margin-top: 0.5rem;
  }
</style>
