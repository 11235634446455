<script>
  import { getContext, onMount } from 'svelte';
  import { getStore, store } from '../../../stores/store.js';

  import { dataSharingPermissions } from '../../../utils/permissions';

  import Input from '../../fields/Input.svelte';
  import { payloadErrors } from '../../../utils/payload-errors.js';

  const { close } = getContext('simple-modal');

  onMount(async () => {
    const store = getStore('business');
    const payload = store.dataSharing?.consentPayload
      ? JSON.parse(store.dataSharing?.consentPayload)
      : null;
    if (payload) {
      formValue.cpf = payload?.data?.loggedUser?.document?.identification ?? '';
      formValue.cnpj =
        payload?.data?.businessEntity?.document?.identification ?? '';
      formValue.expirationDateTime = payload?.data?.expirationDateTime ?? '';
      formValue.permissions = payload?.data?.permissions ?? [];
    }
    validatePayload();
  });

  let errors = [];

  let formValue = {
    cpf: '',
    cnpj: '',
    expirationDateTime: '',
    permissions: [],
  };

  const validatePayload = () => {
    validateCPF();
    validateCPFPermission();
    validateCNPJPermission();
    validateResourceRead();
    validateDateFormat();
  };

  const validateCPF = () => {
    if (!formValue.cpf) {
      addError('CPF_MISSING');
    } else {
      removeError('CPF_MISSING');
    }
  };

  const validateCPFPermission = () => {
    if (
      formValue.cpf &&
      !formValue.cnpj &&
      formValue.permissions.find((permission) =>
        permission.includes('CUSTOMERS_BUSINESS')
      )
    ) {
      addError('CPF_BUSINESS_PERMISSION');
    } else {
      removeError('CPF_BUSINESS_PERMISSION');
    }
  };

  const validateCNPJPermission = () => {
    if (
      formValue.cnpj &&
      formValue.permissions.find((permission) =>
        permission.includes('CUSTOMERS_PERSONAL')
      )
    ) {
      addError('CNPJ_PERSONAL_PERMISSION');
    } else {
      removeError('CNPJ_PERSONAL_PERMISSION');
    }
  };

  const validateResourceRead = () => {
    if (
      !formValue.permissions.find((permission) =>
        permission.includes('RESOURCES_READ')
      )
    ) {
      addError('RESOURCE_READ_MISSING');
    } else {
      removeError('RESOURCE_READ_MISSING');
    }
  };

  const validateDateFormat = () => {
    const regex = new RegExp(
      /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\dZ/
    );
    if (formValue.expirationDateTime) {
      if (!regex.test(formValue.expirationDateTime)) {
        addError('INVALID_DATE');
      } else {
        removeError('INVALID_DATE');
      }
    }
  };

  const addError = (newError) => {
    if (errors.length === 0) {
      errors = [newError];
      return;
    }

    if (errors.find((error) => error === newError)) {
      return;
    }

    errors = [...errors, newError];
  };

  const removeError = (newError) => {
    const index = errors.indexOf(newError);

    if (errors.length === 0 || index === -1) {
      return;
    }
    errors = errors.filter((error) => error !== newError);
  };

  const save = () => {
    store.update((store) => ({
      ...store,
      business: {
        ...store.business,
        dataSharing: {
          ...store.business.dataSharing,
          consentPayload: buildPayload(),
        },
      },
    }));
    close();
  };

  const buildPayload = () => {
    let newPayload = {};
    if (formValue.cpf) {
      newPayload.loggedUser = {
        document: {
          identification: formValue.cpf,
          rel: 'CPF',
        },
      };
    }
    if (formValue.cnpj) {
      newPayload.businessEntity = {
        document: {
          identification: formValue.cnpj,
          rel: 'CNPJ',
        },
      };
    }
    if (formValue.expirationDateTime) {
      newPayload.expirationDateTime = formValue.expirationDateTime;
    }
    newPayload.permissions =
      formValue.permissions.length > 0 ? formValue.permissions : [];
    return JSON.stringify({ data: newPayload }, null, 2);
  };

  const isGroupSelected = (group) => {
    const selectedPermissions = group.filter((groupPermission) =>
      formValue.permissions.includes(groupPermission)
    );
    return selectedPermissions.length === group.length;
  };

  const toggleGroup = (group) => {
    if (isGroupSelected(group)) {
      unSelectGroup(group);
    } else {
      selectGroup(group);
    }
    validatePayload();
  };

  const selectGroup = (group) => {
    group.forEach((groupPermission) => {
      formValue.permissions = formValue.permissions.includes(groupPermission)
        ? formValue.permissions
        : [...formValue.permissions, groupPermission];
    });
  };

  const unSelectGroup = (group) => {
    group.forEach((groupPermission) => {
      const index = formValue.permissions.indexOf(groupPermission);
      formValue.permissions =
        index === -1
          ? formValue.permissions
          : formValue.permissions.filter(
              (permission) => permission !== groupPermission
            );
    });
  };
</script>

<div class="form-container">
  <div class="form-row">
    <div class="input">
      <Input
        text="CPF"
        key="cpf"
        style="width:100%"
        bind:value={formValue.cpf}
        on:change={() => validatePayload()}
      />
    </div>
    <div class="input">
      <Input
        text="CNPJ"
        key="cnpj"
        bind:value={formValue.cnpj}
        on:change={() => validatePayload()}
      />
    </div>
    <div class="input">
      <Input
        text="Data expiração"
        key="expirationDate"
        bind:value={formValue.expirationDateTime}
        on:change={() => validatePayload()}
      />
    </div>
  </div>
  <div class="permissions">
    {#each Object.entries(dataSharingPermissions) as [key, permissions]}
      <div class="permissions-title">
        <span class="permission-type">{key}</span>
        {#key formValue.permissions}
          <label>
            <input
              type="checkbox"
              class="checkbox"
              name={key + '-permission'}
              checked={isGroupSelected(permissions)}
              on:click={() => toggleGroup(permissions)}
            />
            Selecionar tudo
          </label>
        {/key}
      </div>
      <div class="permissions-container">
        {#each permissions as permission}
          <label>
            <input
              type="checkbox"
              class="checkbox"
              bind:group={formValue.permissions}
              name="permissions"
              value={permission}
              on:change={() => validatePayload()}
            />
            {permission}
          </label>
        {/each}
      </div>
    {/each}
  </div>
  <div class="errors-container">
    {#each errors as error}
      <span class="error">{payloadErrors[error]}</span>
    {/each}
  </div>
  <div class="actions">
    <button class="btn" on:click|preventDefault={save}>Salvar</button>
  </div>
</div>

<style>
  .form-container {
    flex: 1;
  }

  .form-row {
    display: flex;
    gap: 1rem;
    width: 100%;
  }

  .input {
    width: 100%;
  }

  .permissions {
    max-height: 40vh;
    overflow-y: auto;
  }

  .permissions-title {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .permissions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
    font-size: 0.6rem;
  }

  .permissions-container label {
    margin: 0;
    background: var(--white-color);
    border-radius: 0.5rem;
    padding: 0.3rem 0.5rem;
  }

  .permission-type {
    display: block;
    font-weight: 700;
    font-size: 0.85rem;
  }

  .btn {
    background: var(--primary-color);
    color: var(--white-color);
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    width: 100%;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .errors-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
  }

  .error {
    display: block;
    padding: 1rem;
    width: 100%;
    background-color: var(--alert-color);
    color: #ffffff;
    border-radius: 0.25rem;
  }
</style>
