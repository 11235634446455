export const payloadErrors = {
  CPF_MISSING: 'O campo CPF é obrigatório!',
  CPF_BUSINESS_PERMISSION:
    'As permissões de CUSTOMERS_BUSINESS só é permitida para consentimentos de pessoa jurídica',
  CNPJ_PERSONAL_PERMISSION:
    'As permissões de CUSTOMERS_PERSONAL só é permitida para consentimentos de pessoa física',
  RESOURCE_READ_MISSING:
    'O campo RESOURCE_READ é obrigatório para todos os consentimentos',
  INVALID_DATE:
    'O campo data não está respeitando o formato YYYY-MM-DDThh:mm:ssZ',
};
