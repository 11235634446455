export const financialDataUrlsV1 = [
  {
    type: 'bank-fixed-incomes',
    name: 'Bank Fixed Incomes',
    basePath: '/open-banking/bank-fixed-incomes',
    urls: [
      {
        method: 'GET',
        path: '/v1/investments',
        validateToken: true,
        scope: 'bank-fixed-incomes',
        validateJWS: false,
        consentPermission: 'BANK_FIXED_INCOMES_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/investments/{investmentId}',
        validateToken: true,
        scope: 'bank-fixed-incomes',
        validateJWS: false,
        consentPermission: 'BANK_FIXED_INCOMES_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/investments/{investmentId}/balances',
        validateToken: true,
        scope: 'bank-fixed-incomes',
        validateJWS: false,
        consentPermission: 'BANK_FIXED_INCOMES_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/investments/{investmentId}/transactions',
        validateToken: true,
        scope: 'bank-fixed-incomes',
        validateJWS: false,
        consentPermission: 'BANK_FIXED_INCOMES_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/investments/{investmentId}/transactions-current',
        validateToken: true,
        scope: 'bank-fixed-incomes',
        validateJWS: false,
        consentPermission: 'BANK_FIXED_INCOMES_READ',
        validateConsentId: true,
        mtls: true,
      },
    ],
  },
  {
    type: 'funds',
    name: 'Funds',
    basePath: '/open-banking/funds',
    urls: [
      {
        method: 'GET',
        path: '/v1/investments',
        validateToken: true,
        scope: 'funds',
        validateJWS: false,
        consentPermission: 'FUNDS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/investments/{investmentId}',
        validateToken: true,
        scope: 'funds',
        validateJWS: false,
        consentPermission: 'FUNDS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/investments/{investmentId}/balances',
        validateToken: true,
        scope: 'funds',
        validateJWS: false,
        consentPermission: 'FUNDS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/investments/{investmentId}/transactions',
        validateToken: true,
        scope: 'funds',
        validateJWS: false,
        consentPermission: 'FUNDS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/investments/{investmentId}/transactions-current',
        validateToken: true,
        scope: 'funds',
        validateJWS: false,
        consentPermission: 'FUNDS_READ',
        validateConsentId: true,
        mtls: true,
      },
    ],
  },
  {
    type: 'exchanges',
    name: 'Exchanges',
    basePath: '/open-banking/exchanges',
    urls: [
      {
        method: 'GET',
        path: '/v1/operations',
        validateToken: true,
        scope: 'exchanges',
        validateJWS: false,
        consentPermission: 'EXCHANGES_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/operations/{operationId}',
        validateToken: true,
        scope: 'exchanges',
        validateJWS: false,
        consentPermission: 'EXCHANGES_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/operations/{operationId}/events',
        validateToken: true,
        scope: 'exchanges',
        validateJWS: false,
        consentPermission: 'EXCHANGES_READ',
        validateConsentId: true,
        mtls: true,
      }
    ],
  }
];

export const financialDataUrlsV2 = [
  {
    type: 'accounts',
    name: 'Accounts',
    basePath: '/open-banking/accounts',
    urls: [
      {
        method: 'GET',
        path: '/v2/accounts',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{accountId}',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{accountId}/balances',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{accountId}/transactions',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{accountId}/overdraft-limits',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{accountId}/transactions-current',
      },
    ],
  },
  {
    type: 'credit-cards-accounts',
    name: 'Credit Cards',
    basePath: '/open-banking/credit-cards-accounts',
    urls: [
      {
        method: 'GET',
        path: '/v2/accounts',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{creditCardAccountId}',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{creditCardAccountId}/bills',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{creditCardAccountId}/bills/{billId}/transactions',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{creditCardAccountId}/limits',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{creditCardAccountId}/transactions',
      },
      {
        method: 'GET',
        path: '/v2/accounts/{creditCardAccountId}/transactions-current',
      },
    ],
  },
  {
    type: 'customers',
    name: 'Customers',
    basePath: '/open-banking/customers',
    urls: [
      {
        method: 'GET',
        path: '/v2/personal/identifications',
      },
      {
        method: 'GET',
        path: '/v2/personal/qualifications',
      },
      {
        method: 'GET',
        path: '/v2/personal/financial-relations',
      },
      {
        method: 'GET',
        path: '/v2/business/identifications',
      },
      {
        method: 'GET',
        path: '/v2/business/qualifications',
      },
      {
        method: 'GET',
        path: '/v2/business/financial-relations',
      },
    ],
  },
  {
    type: 'financings',
    name: 'Financings',
    basePath: '/open-banking/financings',
    urls: [
      {
        method: 'GET',
        path: '/v2/contracts',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/warranties',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/scheduled-instalments',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/payments',
      },
    ],
  },
  {
    type: 'invoice-financings',
    name: 'Invoice financings',
    basePath: '/open-banking/invoice-financings',
    urls: [
      {
        method: 'GET',
        path: '/v2/contracts',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/warranties',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/scheduled-instalments',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/payments',
      },
    ],
  },
  {
    type: 'loans',
    name: 'Loans',
    basePath: '/open-banking/loans',
    urls: [
      {
        method: 'GET',
        path: '/v2/contracts',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/warranties',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/scheduled-instalments',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/payments',
      },
    ],
  },
  {
    type: 'unarranged-accounts-overdraft',
    name: 'Unarranged accounts overdraft',
    basePath: '/open-banking/unarranged-accounts-overdraft',
    urls: [
      {
        method: 'GET',
        path: '/v2/contracts',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/warranties',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/scheduled-instalments',
      },
      {
        method: 'GET',
        path: '/v2/contracts/{contractId}/payments',
      },
    ],
  },
  {
    type: 'resources',
    name: 'Resources',
    basePath: '/open-banking/resources',
    urls: [
      {
        method: 'GET',
        path: '/v2/resources',
        validateToken: true,
        scope: 'resources',
        validateJWS: false,
        consentPermission: 'RESOURCES_READ',
        validateConsentId: true,
        mtls: true,
      },
    ],
  },
];

export const financialDataUrlsV3 = [
  {
    type: 'resources',
    name: 'Resources',
    basePath: '/open-banking/resources',
    urls: [
      {
        method: 'GET',
        path: '/v3/resources',
        validateToken: true,
        scope: 'resources',
        validateJWS: false,
        consentPermission: 'RESOURCES_READ',
        validateConsentId: true,
        mtls: true,
      },
    ],
  }
]

